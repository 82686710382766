import { Formik, Form, Field, ErrorMessage } from 'formik';
import { DateTime } from 'luxon';
import { getIconColor, getInputColor } from "utility";
import { mutate } from 'swr';
import * as yup from 'yup';
import axios from 'axios';
const Swal = window.Swal;

function Profile({ profile, setLoading }) {
    return (
        <div className="mx-5 pt-5">
            <Formik
                enableReinitialize
                initialValues={{
                    userId: profile.userId ?? "", dob: DateTime.fromISO(profile.dob).toFormat('yyyy-MM-dd') ?? "",
                    phoneNumber: profile.phoneNumber ?? "", email: profile.email ?? "", firstName: profile.firstName, lastName: profile.lastName
                }}
                validationSchema={yup.object({
                    firstName: yup.string().required(),
                    lastName: yup.string().required(),
                    userId: yup.string().required(),
                    dob: yup.string().required(),
                    phoneNumber: yup.number().required(),
                    email: yup.string().email().required()
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    setLoading(true);
                    let updateUser = { ...values };
                    try {
                        const put = await axios.put(window.api + '/members/line/' + profile.lineId, updateUser);
                        if (put.errors) {
                            setSubmitting(false);
                            return Swal.fire({
                                title: 'ผิดพลาด!',
                                text: put.message,
                                icon: 'error'
                            });
                        }
                        await Swal.fire({
                            title: "อัพเดทสำเร็จ",
                            icon: "success",
                        });
                        setLoading(false);
                        setSubmitting(false);
                        mutate(profile.lineId);
                    } catch (e) {
                        await Swal.fire({
                            title: 'ผิดพลาด!',
                            icon: 'error',
                            text: e.response.data?.Error,
                        });
                        setSubmitting(false);
                        setLoading(false);
                        mutate(profile.lineId);
                    }
                }}
            >
                {({ errors, touched, setFieldTouched, handleChange, submitForm }) => (
                    <Form className='px-5 pt-5 pb-3' style={{ color: 'white', backgroundColor: '#4b4a48', borderRadius: '15px' }}>
                        <div className="has-text-centered pb-2" style={{ fontSize: '1.2rem' }}>ข้อมูลเพื่อรับรางวัล</div>
                        <div className="field">
                            <label className="label" style={{ color: 'white' }}>ชื่อผู้ใช้</label>
                            <div className="control has-icons-left has-icons-right">
                                <Field className={`input`} type="text" name="userId" disabled />
                                <span className={`icon is-small is-left`}>
                                    <i className="fas fa-user"></i>
                                </span>
                            </div>
                            <ErrorMessage name="user" component="p" className="help is-danger" />
                        </div>
                        <div className="field">
                            <label className="label" style={{ color: 'white' }}>ชื่อจริง</label>
                            <div className="control has-icons-left has-icons-right">
                                <Field className={`input ${getInputColor(errors.firstName, touched.firstName)}`} type="text" name="firstName" placeholder="" disabled />
                                <span className={`icon is-small is-left ${getIconColor(errors.firstName, touched.firstName)}`}>
                                    <i className="fas fa-user"></i>
                                </span>
                                <span className={`icon is-small is-right ${getIconColor(errors.firstName, touched.firstName)}`}>
                                    <i className={`fas fa-check `}></i>
                                </span>
                            </div>
                            <ErrorMessage name="firstName" component="p" className="help is-danger" />
                        </div>
                        <div className="field">
                            <label className="label" style={{ color: 'white' }}>นามสกุล</label>
                            <div className="control has-icons-left has-icons-right">
                                <Field className={`input ${getInputColor(errors.lastName, touched.lastName)}`} type="text" name="lastName" placeholder="" disabled />
                                <span className={`icon is-small is-left ${getIconColor(errors.lastName, touched.lastName)}`}>
                                    <i className="fas fa-user"></i>
                                </span>
                                <span className={`icon is-small is-right ${getIconColor(errors.lastName, touched.lastName)}`}>
                                    <i className={`fas fa-check `}></i>
                                </span>
                            </div>
                            <ErrorMessage name="lastName" component="p" className="help is-danger" />
                        </div>
                        <div className="field">
                            <label className="label" style={{ color: 'white' }}>วันเกิด</label>
                            <div className="control has-icons-left">
                                <Field className={`input`} type="date" name="dob" disabled />
                                <span className={`icon is-small is-left`}>
                                    <i className="fas fa-birthday-cake"></i>
                                </span>
                            </div>
                            <ErrorMessage name="dob" component="p" className="help is-danger" />
                        </div>
                        <div className="field">
                            <label className="label" style={{ color: 'white' }}>เบอร์โทร</label>
                            <div className="control has-icons-left has-icons-right">
                                <Field className={`input`} type="text" name="phoneNumber" maxLength="10" disabled />
                                <span className={`icon is-small is-left`}>
                                    <i className="fas fa-phone"></i>
                                </span>
                            </div>
                            <ErrorMessage name="phoneNumber" component="p" className="help is-danger" />
                        </div>
                        <div className="field">
                            <label className="label" style={{ color: 'white' }}>อีเมล</label>
                            <div className="control has-icons-left has-icons-right">
                                <Field onChange={e => { setFieldTouched('email'); handleChange(e); }} className={`input ${getInputColor(errors.email, touched.email)}`} type="text" name="email" />
                                <span className={`icon is-small is-left ${getIconColor(errors.email, touched.email)}`}>
                                    <i className="fas fa-envelope"></i>
                                </span>
                                <span className={`icon is-small is-right ${getIconColor(errors.email, touched.email)}`}>
                                    <i className="fas fa-check"></i>
                                </span>
                            </div>
                            <ErrorMessage name="email" component="p" className="help is-danger" />
                        </div>
                        <div className="field is-grouped is-grouped-centered">
                            <div className="control has-text-centered">
                                {/* <SuspenseImg width="60%" src="/assets/buttons/confirm.png" onClick={submitForm} /> */}
                                <span onClick={submitForm}>Confirm</span>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default Profile;