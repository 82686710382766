import { Formik, Form, Field, ErrorMessage } from 'formik';
import { getIconColor, getInputColor } from "utility";
import { mutate } from 'swr';
import * as yup from 'yup';
import axios from 'axios';
import _ from 'lodash';
const Swal = window.Swal;

function Register({ lineProfile, setLoading, userId }) {
    return (
        <div className="mx-5 pt-5">
            <Formik
                initialValues={{ firstName: '', lastName: '', userId: userId, dob: '', phoneNumber: '', lineUser: '' }}
                validationSchema={yup.object({
                    firstName: yup.string().required(),
                    lastName: yup.string().required(),
                    userId: yup.string().min(9, 'กรุณากรอกอย่างน้อย 9 ตัวอักษร').required().test('checkUserId', 'userId ต้องเริ่มต้นด้วย ufa800', (value, context) => new Promise(resolve => preValidate(resolve, value, context))),
                    dob: yup.string().required(),
                    lineUser: yup.string(),
                    phoneNumber: yup.number().min(9, 'กรุณากรอกอย่างน้อย 9 ตัวอักษร').required()
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    setLoading(true);
                    Object.keys(values).map(t => values[t] = values[t].trim())
                    try {
                        const post = await axios.post(window.api + '/members', values);
                        if (post.errors) {
                            setSubmitting(false);
                            return Swal.fire({
                                title: 'ผิดพลาด!',
                                text: post.message,
                                icon: 'error'
                            });
                        }
                        await Swal.fire({
                            title: "สมัครสำเร็จ",
                            icon: "success",
                        });
                        setLoading(false);
                        setSubmitting(false);
                        mutate(lineProfile.userId);
                    } catch (e) {
                        await Swal.fire({
                            title: 'ผิดพลาด!',
                            text: e.response.data?.Error,
                            icon: 'error'
                        });
                        setSubmitting(false);
                        setLoading(false);
                        mutate(lineProfile.userId);
                    }
                }}
            >
                {({ errors, touched, submitForm }) => (
                    <Form className='p-5' style={{ color: 'white', backgroundColor: '#4b4a48', borderRadius: '15px' }}>
                        <div className="has-text-centered pb-4" style={{ fontSize: '1.2rem' }}>ข้อมูลเพื่อรับรางวัล</div>
                        <div className="field">
                            <label className="label" style={{ color: 'white' }}>ชื่อผู้ใช้<span style={{ color: 'red' }}>**</span></label>
                            <div className="control has-icons-left has-icons-right">
                                <Field disabled className={`input ${getInputColor(errors.userId, touched.userId)}`} type="text" name="userId" placeholder="ufa800XXXX" />
                                <span className={`icon is-small is-left ${getIconColor(errors.userId, touched.userId)}`}>
                                    <i className="fas fa-user"></i>
                                </span>
                                <span className={`icon is-small is-right ${getIconColor(errors.userId, touched.userId)}`}>
                                    <i className={`fas fa-check `}></i>
                                </span>
                            </div>
                            <ErrorMessage name="userId" component="p" className="help is-danger" />
                        </div>
                        <div className="field">
                            <label className="label" style={{ color: 'white' }}>ชื่อจริง<span style={{ color: 'red' }}>**</span></label>
                            <div className="control has-icons-left has-icons-right">
                                <Field className={`input ${getInputColor(errors.firstName, touched.firstName)}`} type="text" name="firstName" placeholder="" />
                                <span className={`icon is-small is-left ${getIconColor(errors.firstName, touched.firstName)}`}>
                                    <i className="fas fa-user"></i>
                                </span>
                                <span className={`icon is-small is-right ${getIconColor(errors.firstName, touched.firstName)}`}>
                                    <i className={`fas fa-check `}></i>
                                </span>
                            </div>
                            <ErrorMessage name="firstName" component="p" className="help is-danger" />
                        </div>
                        <div className="field">
                            <label className="label" style={{ color: 'white' }}>นามสกุล<span style={{ color: 'red' }}>**</span></label>
                            <div className="control has-icons-left has-icons-right">
                                <Field className={`input ${getInputColor(errors.lastName, touched.lastName)}`} type="text" name="lastName" placeholder="" />
                                <span className={`icon is-small is-left ${getIconColor(errors.lastName, touched.lastName)}`}>
                                    <i className="fas fa-user"></i>
                                </span>
                                <span className={`icon is-small is-right ${getIconColor(errors.lastName, touched.lastName)}`}>
                                    <i className={`fas fa-check `}></i>
                                </span>
                            </div>
                            <ErrorMessage name="lastName" component="p" className="help is-danger" />
                        </div>
                        <div className="field">
                            <label className="label" style={{ color: 'white' }}>วันเกิด<span style={{ color: 'red' }}>**</span></label>
                            <div className="control has-icons-left">
                                <Field className={`input ${getInputColor(errors.dob, touched.dob)}`} type="date" name="dob" />
                                <span className={`icon is-small is-left ${getIconColor(errors.dob, touched.dob)}`}>
                                    <i className="fas fa-birthday-cake"></i>
                                </span>
                            </div>
                            <ErrorMessage name="dob" component="p" className="help is-danger" />
                        </div>
                        <div className="field">
                            <label className="label" style={{ color: 'white' }}>lineId</label>
                            <div className="control has-icons-left has-icons-right">
                                <Field className={`input ${getInputColor(errors.lineUser, touched.lineUser)}`} type="text" name="lineUser" />
                                <span className={`icon is-small is-left ${getIconColor(errors.lineUser, touched.lineUser)}`}>
                                    <i className="fas fa-user"></i>
                                </span>
                                {/* <span className={`icon is-small is-left ${getIconColor(errors.lineUser, touched.lineUser)}`}>
                                    <i className="fas fa-check"></i>
                                </span> */}
                            </div>
                            <ErrorMessage name="lineUser" component="p" className="help is-danger" />
                        </div>
                        <div className="field">
                            <label className="label" style={{ color: 'white' }}>เบอร์โทร<span style={{ color: 'red' }}>**</span></label>
                            <div className="control has-icons-left has-icons-right">
                                <Field className={`input ${getInputColor(errors.phoneNumber, touched.phoneNumber)}`} type="text" name="phoneNumber" maxLength="10" />
                                <span className={`icon is-small is-left ${getIconColor(errors.phoneNumber, touched.phoneNumber)}`}>
                                    <i className="fas fa-phone"></i>
                                </span>
                                <span className={`icon is-small is-left ${getIconColor(errors.phoneNumber, touched.phoneNumber)}`}>
                                    <i className="fas fa-check"></i>
                                </span>
                            </div>
                            <ErrorMessage name="phoneNumber" component="p" className="help is-danger" />
                        </div>
                        <div className="field is-grouped is-grouped-centered">
                            <div className="control has-text-centered">
                                {/* <SuspenseImg width="60%" src="/assets/buttons/confirm.png" onClick={submitForm} /> */}
                                <span onClick={submitForm}>Confirm</span>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div >
    );
};

const validationDebounced = async (resolve, value, context) => {
    if (!/^ufa800/.test(value) || value.length < 9) return resolve(false);
    const formattedValue = _.trim(value)
    const valid = await axios.get(window.api + '/lookup/?userId=' + formattedValue.toLowerCase());
    if (valid.data === 'Found') {
        return resolve(true)
    }
    resolve(context.createError({ message: valid.data, path: 'userId' }))
}
const preValidate = _.debounce(validationDebounced, 600)
export default Register;